<template>
  <div class="grid-column grid-column_span_2">
    <div v-if="isMobile" class="pm-18"></div>
    <h2 class="h2">{{ OKI.title }}</h2>
  </div>
  <div class="grid-column grid-column_span_3">
    <div v-if="isMobile" class="pm-54"></div>
    <h4 class="h4" v-html="OKI.head_text"></h4>
  </div>
  <div class="grid-column grid-column_span_5">
    <Promo
      v-if="OKI.image || OKI.video"
      class="promo_full_width"
      :src-pic="OKI.image.path"
      :src-pic-mobile="OKI.image.mobile_path"
      :src-video="OKI.video.path"
      :src-video-mobile="OKI.video.mobile_path"
      :is-video="OKI.show_video"
    />
  </div>

  <div class="grid-column">
    <h3 class="h3 upper">{{ t(`t3`) }}</h3>
  </div>
  <div class="grid-column grid-column_span_4">
    <div v-if="isMobile" class="pm-54"></div>
    <h2 class="h2-1" v-html="OKI.about_text"></h2>
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <!-- <div class="grid-column grid-column_span_row_3"></div> -->
  <div
    class="grid-column"
    :style="{ 'grid-row': `span ${Math.ceil(OKI.pages.length / 2 + 1)}` }"
  ></div>

  <div class="grid-column grid-column_span_2">
    <Preview
      :title="t(`i1_title`)"
      :desc="t(`i1_desc`)"
      :image="require('@/assets/office/1.jpg')"
      :link="{
        name: 'Education',
        params: paramsLocale,
      }"
    />
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <template v-for="(item, index) in OKIPagesPart1" :key="index">
    <div class="grid-column grid-column_span_2">
      <Preview
        :title="item.title"
        :desc="item.preview_text"
        :image="item.preview.path"
        :link="{
          name: 'Article',
          params: Object.assign({ id: item.id }, paramsLocale),
        }"
      />
    </div>
    <div v-if="isMobile" class="grid-line"></div>
  </template>
  <div class="grid-column grid-column_span_2">
    <Preview
      :title="t(`i4_title`)"
      :desc="t(`i4_desc`)"
      :image="require('@/assets/office/4.jpg')"
      :link="{
        name: 'Innovation',
        params: paramsLocale,
      }"
    />
  </div>
  <div v-if="isMobile" class="grid-line"></div>
  <template v-for="(item, index) in OKIPagesPart2" :key="index">
    <div class="grid-column grid-column_span_2">
      <Preview
        :title="item.title"
        :desc="item.preview_text"
        :image="item.preview.path"
        :link="{
          name: 'Article',
          params: Object.assign({ id: item.id }, paramsLocale),
        }"
      />
    </div>
    <div
      v-if="isMobile && index !== OKIPagesPart2.length - 1"
      class="grid-line"
    ></div>
  </template>
  <div v-if="OKI.pages.length % 2" class="grid-column grid-column_span_2"></div>

  <Team :team="OKI.persons" />

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { mapState, mapGetters } from "vuex";

import Promo from "@/components/Promo.vue";
import Team from "@/components/Team.vue";
import News from "@/components/News.vue";
import Preview from "@/components/Preview.vue";

export default defineComponent({
  name: "Office",
  components: {
    Promo,
    Team,
    News,
    Preview,
  },
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return { t, locale };
  },
  computed: {
    ...mapState(["OKI", "OKIStatus"]),
    ...mapGetters(["OKIPagesPart1", "OKIPagesPart2"]),
  },
});
</script>

<i18n>
{
  "en": {
    "t3": "About",

    "i1_title": "EDUCATION",
    "i1_desc": "The collaboration between educational institutions drives scientific and academic communications and relations",
    "i1_link": "",
    "i4_title": "INNOVATION",
    "i4_desc": "The connection of relevant organisations and development of business opportunities",
    "i4_link": "",
  },
  "ru": {
    "t3": "О нас",
    "i1_title": "ОБРАЗОВАНИЕ",
    "i1_desc": "Взаимодействие образовательных учреждений как определяющий фактор развития научных, академических связей и отношений",
    "i1_link": "",
    "i4_title": "ИННОВАЦИИ",
    "i4_desc": "Установление связей между инновационными компаниями и развитие возможностей в области делового сотрудничества",
    "i4_link": "",
  },
  "ar": {
    "t3": "عن المكتب",
    "i1_title": "التعليم",
    "i1_desc": "تفاعل المؤسسات التعليمية كعامل حاسم في تنمية الروابط والعلاقات العلمية والأكاديمية",
    "i1_link": "",
    "i4_title": "الابتكار",
    "i4_desc": "إقامة روابط بين الشركات المبتكرة وتطوير فرص التعاون التجاري",
    "i4_link": "",
  }
}
</i18n>
